import React, { useEffect, useState } from "react";
import { Tabs, Image } from "antd";
import { Container } from "react-bootstrap";
import { Row, Col } from "antd";
import Card from "react-bootstrap/Card";
import { MdDateRange } from "react-icons/md";
import moment from "moment";
import Link from "next/link";
import ConvertToEn from "utils/convertToEn";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import VideoItem from "../video-item";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const Galeri = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const list = data?.sections[0]?.categories[0]?.contents;
  const list1 = data?.sections[1]?.categories[0]?.contents;

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const Change = (e) => {
    setActiveKey(e);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  };

  return (
    <div id="foto-galeri">
      <div className="galeri">
        <h1>GALERİ</h1>
        <div>
          <div className="head_line">
            <span style={activeKey == "1" ? {} : { float: "right" }}></span>
          </div>
        </div>
        <div className="galeri-foto">
          <Tabs onChange={Change} defaultActiveKey="1">
            <Tabs.TabPane tab="FOTOĞRAFLAR" key="1">
              <Container>
                <Image.PreviewGroup>
                  <Row gutter={[12, 12]} className="galeri-satir">
                    {list?.map((item, i) => (
                      <Col xs={24} sm={24} md={12} lg={8} xxl={8} key={i}>
                        <div className="img-box">
                          <Card>
                            <div className="img">
                              <Image
                                preview={{
                                  mask: () => "",
                                }}
                                src={`${process.env.NEXT_PUBLIC_BASE_URL}/Site/GetFileByte?model=Content&fileName=${item?.imageName}`}
                              />
                            </div>
                            {/* <Card.Body>
                              <Card.Title>
                                <h3>{item.title}</h3>
                              </Card.Title>
                              <Card.Text>
                                <span className="icon">
                                  <MdDateRange />
                                </span>
                                <span>
                                  {moment(item?.editDate).format(
                                    "MMMM Do YYYY"
                                  )}
                                </span>
                              </Card.Text>
                            </Card.Body> */}
                            {/* <Card.Body>
                              <Card.Text>
                                <span className="icon">
                                  <MdDateRange />
                                </span>
                                <span>
                                  {moment(item?.editDate).format(
                                    "MMMM Do YYYY"
                                  )}
                                </span>
                              </Card.Text>
                            </Card.Body> */}
                          </Card>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Image.PreviewGroup>
              </Container>
              <Link
                href={`/${ConvertToEn(
                  data["sections"][0].categories[0].text
                )}-${data["sections"][0].categories[0].id}`}
              >
                <div className="btn">
                  <button>TÜM FOTOĞRAFLAR</button>
                </div>
              </Link>
            </Tabs.TabPane>
            <Tabs.TabPane tab="VİDEOLAR" key="2">
              <Container>
                {/* <Spin indicator={antIcon} spinning={loading}> */}
                <Row gutter={[12, 12]} className="galeri-satir">
                  {list1?.map((item, i) => (
                    <Col xs={24} sm={24} md={12} lg={8} xxl={8} key={i}>
                      <VideoItem
                        href={`${ConvertToEn(
                          data?.sections[1]?.categories[0]?.text
                        )}-${data?.sections[1]?.categories[0].id}/${
                          item?.id
                        }-Detay`}
                        title={item.title}
                        tarih={moment(item?.editDate).format("Do MMMM YYYY")}
                        imageName={item.imageName}
                      />
                      {/* <Link
                                                        href={{
                                                            pathname: `${ConvertToEn(data?.sections[1]?.categories[0]?.text)}-${data?.sections[1]?.categories[0].id}/${item?.id
                                                                }-Detay`,
                                                        }}
                                                    ><a>
                                                            <div className='video-liste'>
                                                                <iframe style={{ marginBottom: "20px" }} src={
                                                                    `https://www.youtube.com/embed${item.url?.substring(
                                                                        item.url?.lastIndexOf("/")
                                                                    )}?rel=0`
                                                                }
                                                                    title="W3Schools Free Online Web Tutorials">
                                                                </iframe>
        
                                                            </div>
                                                        </a></Link> */}
                    </Col>
                  ))}
                </Row>
                {/* </Spin> */}
              </Container>
              <Link
                href={`/${ConvertToEn(
                  data["sections"][1].categories[0].text
                )}-${data["sections"][1].categories[0].id}`}
              >
                <div className="btn">
                  <button>TÜM VİDEOLAR</button>
                </div>
              </Link>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
export default Galeri;
