import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";
import { TbPlayerPlay } from "react-icons/tb";
import { CalendarOutlined } from "@ant-design/icons";
import imgLoader from "utils/nextImageLoader";

const VideoStyle = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  height: 350px;
  margin-bottom: 20px;
  border-radius: 15px;
  position: relative;
  .video-header {
    width: 100%;
    height: 210px;
    background-color: transparent;
    .image {
      position: relative;
      width: 100%;
      height: 210px;
      border-radius: 15px 15px 0 0;
      transition: all 0.5s ease 0s;
      overflow: hidden;
      img {
        position: relative;
        transition: all 0.5s ease 0s;
        border-radius: 15px 15px 0 0;
        &:hover {
          transform: scale(1.1);
          border-radius: 15px;
        }
      }
      .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        border-radius: 50%;
        padding: 18px;
        /* width: 50px;
        height: 50px; */
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#f44336),
          to(rgb(233 30 30 / 31%))
        );
      }
    }
  }
  .container {
    width: 100%;
    height: 140px;
    padding: 25px 20px 0;
    text-align: start;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 15px;

    .title {
      font-size: 0.8rem;
      color: #455f76;
      font-weight: 700;
      overflow: hidden;
      max-height: 100;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      /* &:hover {
        color: rgb(233 30 30 / 31%);
      } */
    }
    .tarih {
      position: absolute;
      bottom: 5px;
      color: #a6a3aa;
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      height: 40;
      svg {
        font-size: 1.1rem;
        margin-right: 5px;
      }
    }
  }
  &:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  }
`;

const VideoItem = ({ href, tarih, title, imageName }) => {
  return (
    <VideoStyle>
      <div class="video-header">
        <div class="video-photo">
          <div className="image">
            <Image
              loader={imgLoader}
              src={
                imageName
                  ? `model=Content&fileName=${imageName}`
                  : "/default.png"
              }
              layout="fill"
            />
            <Link href={href}>
              <a className="icon">
                <TbPlayerPlay />
              </a>
            </Link>
          </div>
        </div>
      </div>

      <div class="container">
        <p className="title">{title}</p>
        <p className="tarih">
          <CalendarOutlined />
          {tarih}
        </p>
      </div>
    </VideoStyle>
  );
};

export default VideoItem;
